import { initialState } from "./AppContext"
import {
  saveContextToSession,
  getContextFromSession,
  removeContextFromSession,
} from "./services/context"
import { isObjectEmpty } from "../services/general"

const AUTH = {
  SAVE_AUTH: "SAVE_AUTH",
}

const FEEDBACK = {
  SAVE_FEEDBACK: "SAVE_FEEDBACK",
}

const ENROLLMENT = {
  SAVE_ENROLLMENT: "SAVE_ENROLLMENT",
  SAVE_EMPLOYEE_DETAILS: "SAVE_EMPLOYEE_DETAILS",
  SAVE_EMERGENCY_CONTACT_DETAILS: "SAVE_EMERGENCY_CONTACT_DETAILS",
  SAVE_AGREE_ENROLLMENT: "SAVE_AGREE_ENROLLMENT",
  SAVE_ID_FRONT: "SAVE_ID_FRONT",
  SAVE_ID_BACK: "SAVE_ID_BACK",
}

const DOCUMENTS = {
  SAVE_DOCUMENT: "SAVE_DOCUMENT",
  SAVE_DOCUMENTS: "SAVE_DOCUMENTS",
  RESET_DOCUMENTS: "RESET_DOCUMENTS",
}

const RESET_DETAILS = "RESET_DETAILS"
const SHOW_MODAL = "SHOW_MODAL"
const HIDE_MODAL = "HIDE_MODAL"
const SHOW_TOAST = "SHOW_TOAST"
const HIDE_TOAST = "HIDE_TOAST"
const SHOW_DISCLAIMER = "SHOW_DISCLAIMER"
const HIDE_DISCLAIMER = "HIDE_DISCLAIMER"
const SAVE_SCHEDULE = "SAVE_SCHEDULE"
const SAVE_CONTEXT_TO_SESSION = "SAVE_CONTEXT_TO_SESSION"
const GET_CONTEXT_FROM_SESSION = "GET_CONTEXT_FROM_SESSION"
const REMOVE_CONTEXT_FROM_SESSION = "REMOVE_CONTEXT_FROM_SESSION"

const SET_SELF_REQUEST_COMPLETE = "SET_SELF_REQUEST_COMPLETE"

export let AppReducer = (state, action) => {
  switch (action.type) {
    case AUTH.SAVE_AUTH:
      return {
        ...state,
        auth: { ...state.auth, ...action.payload },
      }
    case FEEDBACK.SAVE_FEEDBACK:
      return {
        ...state,
        feedback: { ...state.feedback, ...action.payload },
      }
    case ENROLLMENT.SAVE_ENROLLMENT:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          ...action.payload,
        },
      }
    case ENROLLMENT.SAVE_EMPLOYEE_DETAILS:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          employee: {
            ...state.enrollment.employee,
            ...action.payload,
          },
        },
      }
    case ENROLLMENT.SAVE_EMERGENCY_CONTACT_DETAILS:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          emergencyContact: {
            ...state.enrollment.emergencyContact,
            ...action.payload,
          },
        },
      }
    case ENROLLMENT.SAVE_AGREE_ENROLLMENT:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          agreeToConsent: action.payload,
        },
      }

    case ENROLLMENT.SAVE_ID_FRONT:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          id: {
            ...state.enrollment.id,
            front: action.payload,
          },
        },
      }

    case ENROLLMENT.SAVE_ID_BACK:
      return {
        ...state,
        enrollment: {
          ...state.enrollment,
          id: {
            ...state.enrollment.id,
            back: action.payload,
          },
        },
      }

    case SHOW_MODAL:
      return { ...state, modal: { ...action.payload, isActive: true } }

    case HIDE_MODAL:
      return { ...state, modal: { ...initialState.modal, isActive: false } }

    case SHOW_TOAST:
      return { ...state, toast: { ...action.payload, isActive: true } }

    case HIDE_TOAST:
      return { ...state, toast: { ...initialState.toast } }

    case SHOW_DISCLAIMER:
      return { ...state, disclaimer: { ...initialState.disclaimer } }

    case HIDE_DISCLAIMER:
      return { ...state, disclaimer: { ...action.payload, isActive: false } }

    case SAVE_SCHEDULE:
      return {
        ...state,
        selfRequest: {
          ...state.selfRequest,
          schedule: { ...action.payload },
        },
      }

    case RESET_DETAILS:
      return { ...initialState }

    case SAVE_CONTEXT_TO_SESSION:
      let toBeSaved = {}
      for (let key in action.payload) toBeSaved[key] = action.payload[key]
      saveContextToSession({ ...toBeSaved })
      return toBeSaved
    case GET_CONTEXT_FROM_SESSION:
      let returnState = { ...getContextFromSession() }
      if (isObjectEmpty(returnState)) return { ...initialState }
      return {
        ...returnState,
        // patientEnrollment: {
        //   // to change to selfRequest/refer info
        //   ...returnState.patientEnrollment,
        //   agreeToConsent: [],
        // },
        // doctorEnrollment: {
        //   ...returnState.doctorEnrollment,
        //   agreeToConsent: [],
        // },
        modal: {
          ...initialState.modal,
        },
      }

    case REMOVE_CONTEXT_FROM_SESSION:
      removeContextFromSession()
      return {
        ...initialState,
      }

    case SET_SELF_REQUEST_COMPLETE:
      return {
        ...state,
        selfRequest: {
          ...state.selfRequest,
          requestComplete: true,
        },
      }

    case DOCUMENTS.SAVE_DOCUMENT:
      return {
        ...state,
        documents: [...state.documents, action.payload],
      }

    case DOCUMENTS.SAVE_DOCUMENTS:
      return {
        ...state,
        documents: [...action.payload],
      }

    case DOCUMENTS.RESET_DOCUMENTS:
      return {
        ...state,
        documents: [],
      }

    default:
      return null
  }
}
